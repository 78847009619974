import { useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { StaticImageData } from 'next/image'
import { useRouter } from 'next/router'

import Anchor from '@ecm/ui/element/Anchor'
import CloudflareImage from '@ecm/ui/element/CloudflareImage'
import useSmallScreen from '@ecm/ui/hook/UseSmallScreen'

import ecmLogo from '/public/images/logo/ecm-legal-zoom.svg'
import ecmNoTextLogo from '/public/images/logo/ecm-no-text.svg'

type LogoProps = {
  logo?: StaticImageData
}

const Logo = ({ logo }: LogoProps) => {
  const [isPartnerPage, setIsPartnerPage] = useState(false)

  const router = useRouter()
  const isSmallScreen = useSmallScreen()
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'))

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    setIsPartnerPage(router.asPath.includes('partners'))
  }, [router])

  let logoWidth: number

  if (isPartnerPage) {
    logoWidth = isSmallScreen ? 244 : 361
  } else {
    logoWidth = isMediumScreen ? 42 : 240
  }

  return (
    <Anchor href="/" prefetch={false}>
      <CloudflareImage
        src={logo ?? (isMediumScreen ? ecmNoTextLogo : ecmLogo)}
        width={logoWidth}
        height={38}
      />
    </Anchor>
  )
}

export default Logo
