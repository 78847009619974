import React, { CSSProperties, forwardRef, ReactNode } from 'react'
import { styled, Theme } from '@mui/material'

import Color from '@ecm/ui/element/Color'
import Spinner from '@ecm/ui/element/Spinner'

export type ButtonProps = {
  hideSmall?: boolean
  children?: ReactNode
  className?: string
  disabled?: boolean
  fontWeight?: number
  green?: boolean
  hidden?: boolean
  id?: string
  long?: boolean
  margin?: string
  mdAboveHide?: boolean
  mdBelowHide?: boolean
  maxWidth?: string
  minWidth?: string
  narrow?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  primary?: boolean
  outline?: boolean
  red?: boolean
  shadow?: boolean
  short?: boolean
  small?: boolean
  smoke?: boolean
  style?: CSSProperties
  submitting?: boolean
  text?: string
  type?: 'submit' | 'button'
  white?: boolean
  whiteOutline?: boolean
  width?: string
  redNoOutline?: boolean
  borderRadius?: string
}

const getBaseColor = (props: ButtonProps) => {
  if (props.primary) {
    return {
      background: Color.saint,
      border: null,
      font: Color.white,
    }
  } else if (props.outline) {
    return {
      background: null,
      border: Color.saint,
      font: Color.saint,
    }
  } else if (props.green) {
    return {
      background: Color.lucky,
      border: null,
      font: Color.white,
    }
  } else if (props.white) {
    return {
      background: Color.white,
      border: Color.white,
      font: Color.saint,
    }
  } else if (props.redNoOutline) {
    return {
      background: null,
      border: Color.white,
      font: Color.love,
    }
  } else if (props.red) {
    return {
      background: Color.love,
      border: Color.love,
      font: Color.white,
    }
  } else if (props.whiteOutline) {
    return {
      background: null,
      border: Color.white,
      font: Color.white,
    }
  } else if (props.smoke) {
    return {
      background: Color.smoke,
      border: Color.smoke,
      font: Color.white,
    }
  } else {
    return {
      background: null,
      border: Color.saint,
      font: Color.saint,
    }
  }
}

export const Button = styled(
  forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    { children, className, disabled, id, onClick, submitting, type }: ButtonProps,
    ref
  ) {
    const isUnUsable = disabled || submitting
    return (
      <button
        className={`${className} ${isUnUsable && 'disabled'}`}
        disabled={isUnUsable}
        id={id}
        onClick={isUnUsable ? () => false : onClick}
        ref={ref}
        type={type}
      >
        <div className="children">{children}</div>
        {submitting && <Spinner className="spinner" />}
      </button>
    )
  })
)((props: ButtonProps & { theme: Theme }) => {
  const { theme } = props
  const color = getBaseColor(props)
  return {
    /* eslint-disable sort-keys -- order of hover/active is significant to css */
    '&.disabled': {
      background: color.background ? color.background.lighter : 'none',
      cursor: 'not-allowed',
    },
    '&:hover': {
      background: color.background ? color.background.lighter : 'none',
      border: color.border ? `2px solid ${color.border.lighter}` : 'none',
      // boxShadow  : "0px 2px 6px rgba(0, 0, 0, 0.25)",
      color: color.font.lighter,
      // transform  : "translateY(-0.1em)"
    },

    '&:active': {
      background: color.background ? color.background.darker : 'none',
      border: color.border ? `2px solid ${color.border.darker}` : 'none',
      color: color.font.darker,
    },

    '.children': { visibility: props.submitting ? 'hidden' : 'visible' },

    '.spinner': { display: props.submitting ? 'initial' : 'none' },

    background:
      color.background && props.submitting
        ? color.background.darker
        : color.background
        ? color.background.normal
        : 'none',
    border: color.border ? `2px solid ${color.border.normal}` : 'none',
    borderRadius: props.borderRadius ? props.borderRadius : '3px',
    boxShadow: props.shadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
    color: color.font.normal,
    cursor: 'pointer',
    fontFamily: '"Mulish", sans-serif',
    fontSize: props.small ? '12px' : '16px',
    fontWeight: props.fontWeight || 800,
    display: props.hidden ? 'none' : undefined,
    height: props.short ? '3em' : '3.5em',
    margin: props.margin || '0',
    maxWidth: props.maxWidth,
    minWidth: props.minWidth,
    padding: props.small
      ? '0em 1em'
      : props.short
      ? '0.25em 2em'
      : props.narrow
      ? '0em 0em'
      : '1em 2em',
    pointerEvents: props.submitting ? 'none' : 'initial',
    position: 'relative',
    transition: '400ms ease-out',
    width: props.width,
    [theme.breakpoints.down('sm')]: {
      margin: props.margin ? props.margin : '.5em 0',
      width: props.width ? props.width : '100%',
    },
    [theme.breakpoints.up('md')]: { display: props.mdAboveHide ? 'none' : undefined },
    [theme.breakpoints.down('md')]: {
      display: props.mdBelowHide ? 'none' : undefined,
      padding: props.long ? '0em 2.5em' : undefined,
    },
  }
})

export default Button
