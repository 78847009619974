import { styled } from '@mui/material'

export type SpinnerProps = {
  className?: string
}

export const Spinner = styled(({ className }: SpinnerProps) => <div className={className} />)({
  '@keyframes spinner': { to: { transform: 'rotate(360deg)' } },

  animation: 'spinner 600ms linear infinite',
  border: '0.25em solid #ccc',
  borderRadius: '50%',
  borderTopColor: '#000',
  boxSizing: 'border-box',
  height: '2em',
  left: 'calc((100% - 2em)/2)',
  margin: 'auto',
  position: 'absolute',
  top: 'calc((100% - 2em)/2)',
  width: '2em',
})

export default Spinner
