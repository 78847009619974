import dynamic from 'next/dynamic'

import Dropdown from '@ecm/ui/element/Dropdown'

const DynamicDropdownContent = dynamic(() => import('@ecm/ui/component/menu/CompanyContent'))

const CompanyDropdown = () => (
  <Dropdown text="Company" left="-15px" width="130px">
    <DynamicDropdownContent />
  </Dropdown>
)

export default CompanyDropdown
