import dynamic from 'next/dynamic'

import Dropdown from '@ecm/ui/element/Dropdown'

const DynamicDropdownContent = dynamic(() => import('@ecm/ui/component/menu/ProductsContent'))

const ProductsDropdown = () => (
  <Dropdown text="Products" dataTestId="products-dropdown-target" disableRelativePosition>
    <DynamicDropdownContent />
  </Dropdown>
)

export default ProductsDropdown
