import Image, { ImageLoaderProps, StaticImageData } from 'next/image'

import { runEff } from '@ecm/effect'

const isSvg = (src: string) => src.split('.').pop() === 'svg'

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src
}

const staticImageLoader = ({ src }: ImageLoaderProps) => `/${normalizeSrc(src)}`

const cloudflareLoader = ({ src, width, quality }: ImageLoaderProps) => {
  // because cloudflare does not support svg files
  if (isSvg(src)) {
    return `/${normalizeSrc(src)}`
  }

  const params = [`width=${width}`]

  if (quality) {
    params.push(`quality=${quality}`)
  }

  const paramsString = params.join(',')

  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

type CloudflareImageProps = Omit<React.ComponentProps<typeof Image>, 'loader'> & {
  src: string | StaticImageData
}

const CloudflareImage = (props: CloudflareImageProps) => {
  const loadCloudflareImage = runEff(eff => eff.ask().loadCloudflareImage)

  const src = typeof props.src === 'string' ? props.src : props.src.src

  const imageName = src.split('/').pop()?.split('.').shift()

  return (
    <Image
      alt={imageName}
      {...props}
      loader={loadCloudflareImage ? cloudflareLoader : staticImageLoader}
      unoptimized={!loadCloudflareImage || isSvg(src)}
    />
  )
}

export default CloudflareImage
