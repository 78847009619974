import { Divider, Grid } from '@mui/material'
import Link from 'next/link'

import CompanyDropdown from '@ecm/ui/component/dropdown/CompanyDropdown'
import ProductsDropdown from '@ecm/ui/component/dropdown/ProductsDropdown'
import Anchor from '@ecm/ui/element/Anchor'
import { Button } from '@ecm/ui/element/Button'
import Color from '@ecm/ui/element/Color'

type DesktopNavProps = {
  headerCta?: string
  headerCtaHref: string
  showHeaderCta: boolean
}

const DesktopNav = ({ headerCta, headerCtaHref, showHeaderCta }: DesktopNavProps) => (
  <>
    <Grid
      component="nav"
      container
      justifyContent="space-between"
      alignItems="center"
      width={{ lg: '50%', md: '60%' }}
    >
      <ProductsDropdown />

      <Anchor href="/pricing" prefetch={false} padding="0 0.5em" gray>
        Pricing
      </Anchor>

      <Anchor href="/addresses" prefetch={false} padding="0 0.5em" gray>
        Addresses
      </Anchor>

      <Anchor href="/case-studies" prefetch={false} padding="0 0.5em" gray>
        Case Studies
      </Anchor>

      <CompanyDropdown />
    </Grid>

    <Divider orientation="vertical" sx={{ backgroundColor: Color.smoke.darker, height: '70%' }} />

    <Grid container justifyContent="space-between" alignItems="center" width="240px" wrap="nowrap">
      <Anchor
        href="https://app.earthclassmail.com"
        rel={undefined}
        target={undefined}
        external
        gray
      >
        Log in
      </Anchor>
      {showHeaderCta && (
        <Link passHref href={headerCtaHref} prefetch={false}>
          <Button short green shadow fontWeight={800}>
            {headerCta}
          </Button>
        </Link>
      )}
    </Grid>
  </>
)

export default DesktopNav
