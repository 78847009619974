import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import useWindowSize from '@ecm/ui/hook/UseWindowSize'

const useSmallScreen = () => {
  const [width] = useWindowSize()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  if (width === 0) {
    return null
  }

  return isSmallScreen
}

export default useSmallScreen
