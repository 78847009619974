import { ReactNode } from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { Settings } from '@ecm/config/Settings'
import CloudflareImage from '@ecm/ui/element/CloudflareImage'
import Color from '@ecm/ui/element/Color'

import caretDownIcon from '/public/images/icon/caret-down.svg'

type DropdownProps = {
  children: ReactNode
  text: string
  disableRelativePosition?: boolean
  dataTestId?: string
} & React.ComponentProps<typeof Box>

const Dropdown = ({
  children,
  text,
  disableRelativePosition,
  dataTestId,
  ...otherProps
}: DropdownProps) => (
  <Box
    data-testid={dataTestId}
    position={disableRelativePosition ? 'static' : 'relative'}
    sx={{
      '&:hover > div:nth-of-type(2)': {
        maxHeight: '400px',
        opacity: 1,
        visibility: 'visible',
      },
      '&:hover img[alt="caret-down"]': {
        transform: 'rotate(180deg)',
        transition: 'transform 300ms ease-in',
      },
      'img[alt="caret-down"]': { transition: 'transform 300ms ease-out' },
    }}
  >
    <Grid container sx={{ cursor: 'pointer' }}>
      <Typography variant="p3" fontWeight={700} my="1.5em" mr="0.5em">
        {text}
      </Typography>
      <CloudflareImage src={caretDownIcon} />
    </Grid>

    <Box
      position="absolute"
      left="0"
      top={`${Settings.topMenuHeight}px`}
      bgcolor={Color.white.normal}
      boxShadow="0px 10px 20px rgba(23, 41, 115, 0.06)"
      borderRadius="4px"
      maxHeight="0"
      visibility="hidden"
      overflow="hidden"
      sx={{
        opacity: 0,
        transition: 'all 300ms ease-in-out',
      }}
      {...otherProps}
    >
      {children}
    </Box>
  </Box>
)

export default Dropdown
