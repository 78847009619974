import { SeoMetadataDict } from '@ecm/data/Seo'

const metadataDict: SeoMetadataDict = {
  '404': {
    description: "Unfortunately, the page that you are looking for doesn't exist",
    title: '404 - Not Found | Earth Class Mail',
  },

  addresses: {
    description:
      "80+ US addresses and PO boxes to help you stay within easy reach of customers, business partners and investors, even when you're on the move.",
    title: 'U.S. Virtual Address Network | Earth Class Mail',
  },

  blog: {
    description:
      'Read about how we are building our business, and how our customers are growing theirs. Plus exclusive interviews, written by our team.',
    title: 'Small Business, Startup and Virtual Mail Blog | Earth Class Mail',
  },

  'book-a-demo': {
    description:
      'Tell us about your business challenges and we will give you a tour of our product and show you the benefits of mail automation.',
    title: 'Book A Demo | Earth Class Mail',
  },

  'check-deposit': {
    description:
      'Waiting to get paid can be stresful. Our automated check deposit service helps you get paid faster. We scan your mailed-in checks and deposit them for you.',
    title: 'Automated Check Deposits | Earth Class Mail',
  },

  'company/about': {
    description:
      'Since 2004, Earth Class Mail has scanned more than 10 million pieces of mail and deposited over over 16 million pieces of mail and $1.8 billion in checks.',
    title: 'About Us | Earth Class Mail',
  },

  'company/careers': {
    description:
      "Here you find all our current job openings. Interested in joining the team but don't see a job you want? Send us an email at info@earthclassmail.com.",
    title: 'Careers | Earth Class Mail',
  },

  'company/partnerships': {
    description:
      'Give your business a power boost! We partner with innovative companies that are just as committed to helping you succeed as we are.',
    title: 'Partnerships | Earth Class Mail',
  },

  'company/security': {
    description:
      "Security and confidentiality are extremely important to us and our clients. That's why our service was designed in accordance with HIPAA compliance standards.",
    title: 'World-Class Security | Earth Class Mail',
  },

  contact: {
    description:
      'Do you have a question? Email us or call us. Our lines are open from Monday–Friday 8:00am–5:00pm Central time.',
    title: 'Contact Us | Earth Class Mail',
  },

  'frequently-asked-questions': {
    description:
      'How does virtual mail work? How do you set up a virtual mailbox? Can you buy a virtual address for your business? We answer these questions and many more.',
    title: 'All Your Questions Answered | Earth Class Mail',
  },

  green: {
    description:
      'Learn how you can make your mail eco-friendly with Earth Class Mail. Recycle your junk mail, digitize your documents, and we’ll donate to plant a tree.',
    title: 'Green Initiatives | Earth Class Mail',
  },

  homepage: {
    description:
      'Join thousands of remote individuals and businesses who use Earth Class Mail to access their postal mail online. 16 million pieces of mail processed to date.',
    title: 'Virtual Mail for Businesses and Individuals | Earth Class Mail',
  },

  'how-virtual-mail-works': {
    description:
      'Learn everything you need to know about our virtual mail service, from choosing an address or PO Box to accessing your mail online.',
    title: 'How Virtual Mail Works | Earth Class Mail',
  },

  integrations: {
    description:
      'You can link data automatically to any cloud storage provider or proprietary system. Check deposits can also be integrated with most accounting software.',
    title: 'Software Integrations | Earth Class Mail',
  },

  'mail-forwarding': {
    description:
      'A full scanning and forwarding service for businesses and individuals. Scan all your postal mail and the exterior of your packages, and keep an online record.',
    title: 'Mail Forwarding | Earth Class Mail',
  },

  'mail-scanning': {
    description:
      'We scan all your incoming postal mail and then convert it to high-resolution PDFs which you can read online and search like you do with emails.',
    title: 'Mail Scanning with Full Text Search | Earth Class Mail',
  },

  'mail-shredding': {
    description:
      'All plans offer free, secure shredding of mail you no longer want. Once your documents have been scanned, you can request for the physical copies to be securely shredded and recycled.',
    title: 'Secure Mail and Document Shredding | Earth Class Mail',
  },
  'mail-vendor-general-terms': {
    description:
      'Mail forwarding general terms and conditions.',
    title: 'Mail vendor general terms | Earth Class Mail',
  },

  mobile: {
    description:
      'Choose your preferred mobile mailbox app to access your digitized mail from any device, no matter where you are. Download the Earth Class mail app and log into your account to view, search, share, archive, and securely shred your mail—plus much more!',
    title: 'Access your postal mail on the go',
  },

  pricing: {
    description:
      'Business and individual plans available. Select a plan based on your monthly mail volume or the number of recipients. Plans start at $19 per month.',
    title: 'Flexible Virtual Mail Pricing | Earth Class Mail',
  },

  privacy: {
    description:
      'This is the privacy policy of Earth Class Mail, Inc. (ECM) applicable to the website at www.earthclassmail.com and to our secure hosted software application.',
    title: 'Privacy Policy | Earth Class Mail',
  },

  'quickbooks-online': {
    description:
      'No more stacks of checks on your desk, trips to the bank, or unreliable scanners. Automate your entire check receivables workflow from receipt to deposit.',
    title: 'QuickBooks Online Integration | Earth Class Mail',
  },

  'schedule-demo': {
    description:
      'We would love to learn about your business challenges so we can give you a tour of our product and show you the benefits of mail automation.',
    title: 'Schedule a Demo | Earth Class Mail',
  },

  'terms-of-service': {
    description:
      'If you order services from Earth Class Mail, they will be subject to these Terms of Service.',
    title: 'Terms of Service | Earth Class Mail',
  },

  'virtual-business-address': {
    description:
      'Over 80+ U.S. addresses and PO boxes  available for your business needs. Share your virtual address with customers, business partners, and investors.',
    title: 'Get a Virtual Business Address | Earth Class Mail',
  },

  'virtual-mailbox': {
    description:
      'Receive postal mail at one of our addresses and get digital scans uploaded to your virtual mailbox. Log in to search, sort, sync or even shred your postal mail.',
    title: 'US Virtual Mailbox Service | Earth Class Mail',
  },

  'virtual-mailbox-for-business': {
    description:
      'Our Business mailbox service gives you the flexibility email does. Create a centralized, organized process while ensuring your documents are protected by certified technicians.',
    title: 'Business mailbox - flexibility and security | Earth Class Mail',
  },

  'virtual-po-box': {
    description:
      'Forward your mail to our secure processing facility using our virtual PO boxes and receive digital scans that you can access anywhere.',
    title: 'Virtual PO Box Service | Earth Class Mail',
  },
}

export default metadataDict
