import { useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import dynamic from 'next/dynamic'
import { StaticImageData } from 'next/image'
import { useRouter } from 'next/router'

import { Settings } from '@ecm/config/Settings'
import CloudflareImage from '@ecm/ui/element/CloudflareImage'
import Color from '@ecm/ui/element/Color'
import MainContainer from '@ecm/ui/element/MainContainer'
import useSmallScreen from '@ecm/ui/hook/UseSmallScreen'
import DesktopNav from '@ecm/ui/layout/navigation/DesktopNav'
import Logo from '@ecm/ui/layout/navigation/Logo'

import hamburgerIcon from '/public/images/icon/hamburger.svg'

const DynamicMobileNav = dynamic(() => import('@ecm/ui/layout/navigation/mobile/MobileNav'))

type HeaderProps = {
  headerCta?: string
  headerCtaHref?: string
  hideLinks?: boolean
  hideMobileLinks?: boolean
  logo?: StaticImageData
  bgColor?: string
}

const Header = ({
  headerCta = 'Get started',
  headerCtaHref = '/pricing',
  hideLinks,
  hideMobileLinks,
  logo,
  bgColor = Color.smoke.normal,
}: HeaderProps) => {
  const [showHeaderCta, setHeaderShowCta] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const router = useRouter()
  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    setHeaderShowCta(router.pathname !== headerCtaHref)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  useEffect(() => {
    document.body.style.overflow = isOpen && isSmallScreen ? 'hidden' : 'auto'
  }, [isOpen, isSmallScreen])

  return (
    <Box bgcolor={bgColor} position="sticky" top="0" zIndex="10">
      <MainContainer>
        <Grid
          container
          component="header"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          height={`${Settings.topMenuHeight}px`}
        >
          <Logo logo={logo} />

          {isSmallScreen !== null &&
            (isSmallScreen
              ? !hideMobileLinks && (
                  <>
                    <IconButton onClick={() => setIsOpen(true)} id="hamburger-menu-button">
                      <CloudflareImage src={hamburgerIcon} />
                    </IconButton>
                    <DynamicMobileNav onClose={setIsOpen} isOpen={isOpen} />
                  </>
                )
              : !hideLinks && (
                  <DesktopNav
                    headerCta={headerCta}
                    headerCtaHref={headerCtaHref}
                    showHeaderCta={showHeaderCta}
                  />
                ))}
        </Grid>
      </MainContainer>
    </Box>
  )
}

export default Header
