import React from 'react'
import Container from '@mui/material/Container'
import { SystemCssProperties } from '@mui/system'

type MainContainerOwnProps = {
  sx?: Omit<
    SystemCssProperties,
    'padding' | 'paddingX' | 'paddingLeft' | 'paddingRight' | 'pl' | 'pr'
  >
}

type MainContainerProps = MainContainerOwnProps &
  Omit<React.ComponentProps<typeof Container>, 'sx' | 'maxWidth'>

const MainContainer = ({ children, sx, ...other }: MainContainerProps) => {
  return (
    // eslint-disable-next-line sort-keys
    <Container maxWidth="xl" sx={{ paddingX: { md: '40px', lg: '64px' }, ...sx }} {...other}>
      {children}
    </Container>
  )
}

export default MainContainer
